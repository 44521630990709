import React from "react"
import { FaHome, FaCheck, FaRegSun, FaShopify, FaTicketAlt, FaProductHunt, FaSlidersH, FaUserTie, FaGithubSquare, FaUserTimes } from 'react-icons/fa'


const user = JSON.parse(sessionStorage.getItem('user'))

const ROLES = {
  ADMIN: 2,
  COMERCIAL: 101,
  SOPORTE: 102,
  RECURSOS_HUMANOS: 108,
  NOMINA: 109,
  AGENTE: 110
};

const navigationSuperAdmin = [
  {
    id: "home",
    title: "Inicio",
    type: "item",
    icon: <FaHome size={20} />,
    permissions: ["admin", "editor"],
    navLink: "/home"
  },
  {
    id: "Opciones cliente",
    title: "Opciones cliente",
    type: "collapse",
    icon: <FaUserTie size={20} />,
    permissions: ["admin", "editor"],
    children: [
      {
        id: "crear-cliente",
        title: "gestión cliente",
        type: "item",
        icon: <FaCheck size={12} />,
        permissions: ["admin", "editor"],
        navLink: "/cliente",
      },
      {
        id: "cliente producto",
        title: "cliente producto",
        type: "item",
        icon: <FaCheck size={12} />,
        permissions: ["admin", "editor"],
        navLink: "/cliente-producto",
      },
    ]
  },
  {
    id: "Opciones Producto",
    title: "Opciones Producto",
    type: "collapse",
    icon: <FaProductHunt size={20} />,
    permissions: ["admin", "editor"],
    children: [
      {
        id: "Productos",
        title: "Productos",
        type: "item",
        icon: <FaShopify size={20} />,
        permissions: ["admin", "editor"],
        navLink: "/products"
      },
      {
        id: "ProductsRequest",
        title: "Solicitud por producto",
        type: "item",
        icon: <FaProductHunt size={20} />,
        permissions: ["admin", "editor"],
        navLink: "/product-request"
      },
    ]
  },
  {
    id: "MiHojaVida",
    title: "Mi Hoja Vida",
    type: "item",
    icon: <FaHome size={20} />,
    permissions: ["admin", "editor"],
    navLink: "/mi-hoja-vida"
  },

  {
    id: "tipificacion",
    title: "Tipificacion",
    type: "item",
    icon: <FaSlidersH size={20} />,
    permissions: ["admin", "editor"],
    navLink: "/tipificacion"
  },
  {
    id: "gsistema",
    title: "Sistema",
    type: "collapse",
    icon: <FaRegSun size={20} />,
    permissions: ["admin", "editor"],
    children: [
      {
        id: "sistema1",
        title: "Usuarios",
        type: "item",
        icon: <FaCheck size={12} />,
        permissions: ["admin", "editor"],
        navLink: "/users"
      },
      {
        id: "roles",
        title: "Roles",
        type: "item",
        icon: <FaCheck size={12} />,
        permissions: ["admin", "editor"],
        navLink: "/roles"
      },
      {
        id: "sistema3",
        title: "Centro de costos",
        type: "item",
        icon: <FaCheck size={12} />,
        permissions: ["admin", "editor"],
        navLink: "/centro-costo"
      },
      {
        id: "sistema4",
        title: "Cargo",
        type: "item",
        icon: <FaCheck size={12} />,
        permissions: ["admin", "editor"],
        navLink: "/cargo"
      },
      {
        id: "sistema5",
        title: "Area",
        type: "item",
        icon: <FaCheck size={12} />,
        permissions: ["admin", "editor"],
        navLink: "/area"
      },
    ]
  },
  {
    id: "tickets",
    title: "Tickets",
    type: "collapse",
    icon: <FaTicketAlt size={20} />,
    permissions: ["admin", "editor"],
    children: [
      {
        id: "listado",
        title: "Listado",
        type: "item",
        icon: <FaCheck size={12} />,
        permissions: ["admin", "editor"],
        navLink: "/tickets",
      },
    ]
  },
  {
    id: "Agenda",
    title: "Agenda",
    type: "item",
    icon: <FaHome size={20} />,
    permissions: ["admin", "editor"],
    navLink: "/agenda"
  },
  {
    id: "Inasistencias",
    title: "Inasistencias",
    type: "item",
    icon: <FaUserTimes size={20} />,
    permissions: ["admin", "editor"],
    navLink: "/inasistencias"
  }


];

/* configura items para comercial */
const RecursosHumanos = [
  {
    id: "home",
    title: "Inicio",
    type: "item",
    icon: <FaHome size={20} />,
    permissions: ["admin", "editor"],
    navLink: "/home"
  },
  {
    id: "MiHojaVida",
    title: "Mi Hoja Vida",
    type: "item",
    icon: <FaHome size={20} />,
    permissions: ["admin", "editor"],
    navLink: "/mi-hoja-vida"
  },
  {
    id: "gsistema",
    title: "Sistema",
    type: "collapse",
    icon: <FaRegSun size={20} />,
    permissions: ["admin", "editor"],
    children: [
      {
        id: "sistema1",
        title: "Usuarios",
        type: "item",
        icon: <FaCheck size={12} />,
        permissions: ["admin", "editor"],
        navLink: "/users"
      },
      {
        id: "roles",
        title: "Roles",
        type: "item",
        icon: <FaCheck size={12} />,
        permissions: ["admin", "editor"],
        navLink: "/roles"
      },
      {
        id: "sistema3",
        title: "Centro de costos",
        type: "item",
        icon: <FaCheck size={12} />,
        permissions: ["admin", "editor"],
        navLink: "/centro-costo"
      },
      {
        id: "sistema4",
        title: "Cargo",
        type: "item",
        icon: <FaCheck size={12} />,
        permissions: ["admin", "editor"],
        navLink: "/cargo"
      },
      {
        id: "sistema5",
        title: "Area",
        type: "item",
        icon: <FaCheck size={12} />,
        permissions: ["admin", "editor"],
        navLink: "/area"
      },
    ]
  },
  {
    id: "Inasistencias",
    title: "Inasistencias",
    type: "item",
    icon: <FaUserTimes size={20} />,
    permissions: ["admin", "editor"],
    navLink: "/inasistencias"
  }
];
const Soporte = [
  {
    id: "home",
    title: "Inicio",
    type: "item",
    icon: <FaHome size={20} />,
    permissions: ["admin", "editor"],
    navLink: "/home"
  },
  {
    id: "MiHojaVida",
    title: "Mi Hoja Vida",
    type: "item",
    icon: <FaHome size={20} />,
    permissions: ["admin", "editor"],
    navLink: "/mi-hoja-vida"
  },
  
  {
    id: "tickets",
    title: "Tickets",
    type: "collapse",
    icon: <FaTicketAlt size={20} />,
    permissions: ["admin", "editor"],
    children: [
      {
        id: "listado",
        title: "Listado",
        type: "item",
        icon: <FaCheck size={12} />,
        permissions: ["admin", "editor"],
        navLink: "/tickets",
      },
    ]
  },
  {
    id: "Agenda",
    title: "Agenda",
    type: "item",
    icon: <FaHome size={20} />,
    permissions: ["admin", "editor"],
    navLink: "/agenda"
  }
];
const Nomina = [
  {
    id: "home",
    title: "Inicio",
    type: "item",
    icon: <FaHome size={20} />,
    permissions: ["admin", "editor"],
    navLink: "/home"
  },
  {
    id: "MiHojaVida",
    title: "Mi Hoja Vida",
    type: "item",
    icon: <FaHome size={20} />,
    permissions: ["admin", "editor"],
    navLink: "/mi-hoja-vida"
  },
  {
    id: "Inasistencias",
    title: "Inasistencias",
    type: "item",
    icon: <FaUserTimes size={20} />,
    permissions: ["admin", "editor"],
    navLink: "/inasistencias"
  }
];
const Agente = [
  {
    id: "home",
    title: "Inicio",
    type: "item",
    icon: <FaHome size={20} />,
    permissions: ["admin", "editor"],
    navLink: "/home"
  },
  {
    id: "MiHojaVida",
    title: "Mi Hoja Vida",
    type: "item",
    icon: <FaHome size={20} />,
    permissions: ["admin", "editor"],
    navLink: "/mi-hoja-vida"
  },
  {
    id: "Opciones cliente",
    title: "Opciones cliente",
    type: "collapse",
    icon: <FaUserTie size={20} />,
    permissions: ["admin", "editor"],
    children: [
      {
        id: "crear-cliente",
        title: "gestión cliente",
        type: "item",
        icon: <FaCheck size={12} />,
        permissions: ["admin", "editor"],
        navLink: "/cliente",
      },
      {
        id: "cliente producto",
        title: "cliente producto",
        type: "item",
        icon: <FaCheck size={12} />,
        permissions: ["admin", "editor"],
        navLink: "/cliente-producto",
      },
    ]
  },
  {
    id: "Opciones Producto",
    title: "Opciones Producto",
    type: "collapse",
    icon: <FaProductHunt size={20} />,
    permissions: ["admin", "editor"],
    children: [
      {
        id: "Productos",
        title: "Productos",
        type: "item",
        icon: <FaShopify size={20} />,
        permissions: ["admin", "editor"],
        navLink: "/products"
      },
      {
        id: "ProductsRequest",
        title: "Solicitud por producto",
        type: "item",
        icon: <FaProductHunt size={20} />,
        permissions: ["admin", "editor"],
        navLink: "/product-request"
      },
    ]
  },
  {
    id: "tickets",
    title: "Tickets",
    type: "collapse",
    icon: <FaTicketAlt size={20} />,
    permissions: ["admin", "editor"],
    children: [
      {
        id: "listado",
        title: "Listado",
        type: "item",
        icon: <FaCheck size={12} />,
        permissions: ["admin", "editor"],
        navLink: "/tickets",
      },
    ]
  }
];
const Comercial = [
  {
    id: "home",
    title: "Inicio",
    type: "item",
    icon: <FaHome size={20} />,
    permissions: ["admin", "editor"],
    navLink: "/home"
  },
  {
    id: "Opciones cliente",
    title: "Opciones cliente",
    type: "collapse",
    icon: <FaUserTie size={20} />,
    permissions: ["admin", "editor"],
    children: [
      {
        id: "crear-cliente",
        title: "Gestión cliente",
        type: "item",
        icon: <FaCheck size={12} />,
        permissions: ["admin", "editor"],
        navLink: "/cliente",
      },
      {
        id: "cliente-producto",
        title: "Cliente producto",
        type: "item",
        icon: <FaCheck size={12} />,
        permissions: ["admin", "editor"],
        navLink: "/cliente-producto",
      },
    ]
  },
  {
    id: "Opciones Producto",
    title: "Opciones Producto",
    type: "collapse",
    icon: <FaProductHunt size={20} />,
    permissions: ["admin", "editor"],
    children: [
      {
        id: "Productos",
        title: "Productos",
        type: "item",
        icon: <FaShopify size={20} />,
        permissions: ["admin", "editor"],
        navLink: "/products"
      },
      {
        id: "ProductsRequest",
        title: "Solicitud por producto",
        type: "item",
        icon: <FaProductHunt size={20} />,
        permissions: ["admin", "editor"],
        navLink: "/product-request"
      },
    ]
  },
  {
    id: "MiHojaVida",
    title: "Mi Hoja Vida",
    type: "item",
    icon: <FaHome size={20} />,
    permissions: ["admin", "editor"],
    navLink: "/mi-hoja-vida"
  },
  {
    id: "tickets",
    title: "Tickets",
    type: "collapse",
    icon: <FaTicketAlt size={20} />,
    permissions: ["admin", "editor"],
    children: [
      {
        id: "listado",
        title: "Listado",
        type: "item",
        icon: <FaCheck size={12} />,
        permissions: ["admin", "editor"],
        navLink: "/tickets",
      },
    ]
  },
  {
    id: "Agenda",
    title: "Agenda",
    type: "item",
    icon: <FaHome size={20} />,
    permissions: ["admin", "editor"],
    navLink: "/agenda"
  },
  {
    id: "Inasistencias",
    title: "Inasistencias",
    type: "item",
    icon: <FaUserTimes size={20} />,
    permissions: ["admin", "editor"],
    navLink: "/inasistencias"
  }
];

const roleMenus = {
  [ROLES.ADMIN]: navigationSuperAdmin,
  [ROLES.COMERCIAL]: Comercial,
  [ROLES.RECURSOS_HUMANOS]: RecursosHumanos,
  [ROLES.SOPORTE]: Soporte,
  [ROLES.NOMINA]: Nomina,
  [ROLES.AGENTE]: Agente
};

const navigationConfig = roleMenus[user?.profile] || [];


export default navigationConfig



